import React from 'react'
import ReactSlick from 'react-slick'
import PropTypes from 'prop-types'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {
  Wrapper,
  Img
} from './styles'
import { map } from 'ramda'
import { getTexts } from '../../utilities/translations'

const t = getTexts({
  'en': {
    previous: `Previous`,
    next: `Next`
  },
  'zh': {
    previous: `上页`,
    next: `下一个`
  }
})

const PrevArrow = ({ className, style, onClick }) => (
  <button className={className} style={style} onClick={onClick}>{t.previous}</button>
)

const NextArrow = ({ className, style, onClick }) => (
  <button className={className} style={style} onClick={onClick}><span>{t.next}</span></button>
)

const settings = {
  dots: true,
  fade: true,
  autoplay: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  customPaging: i => <span>0{i + 1}</span>,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />
}

const Slider = ({ images, ...rest }) => (
  <Wrapper {...rest}>
    <ReactSlick {...settings}>
      {map(
        ({ src, isCGI, hasDarkCGILabel }) => <Img 
          key={src} 
          src={src} 
          isCGI={isCGI} 
          hasDarkCGILabel={hasDarkCGILabel}
        />, 
        images
      )}
    </ReactSlick>
  </Wrapper>
)

Slider.propTypes = {
  images: PropTypes.array.isRequired
}

Slider.defaultProps = {
  images: []
}

export default Slider
