import styled, { css } from 'styled-components'
import { colors, sizes, media } from 'styles'

const NAV_HEIGHT = sizes.FOOTER_HEIGHT;

const navTextStyle = css`
  font-size: 14px;
  font-weight: 900;
  line-height: 19px;
`

export const Wrapper = styled.div`
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide > div {
    height: 100%;
  }

  .slick-slider {
    padding-bottom: ${NAV_HEIGHT}px;
    max-height: 768px;
  }

  .slick-dots {
    left: 180px;
    bottom: -2px;
    width: calc(50% - 110px);
    height: ${NAV_HEIGHT}px;
    border-left: 1px solid ${colors.BORDER};
    border-right: 1px solid ${colors.BORDER};
    border-bottom: 1px solid ${colors.BORDER};
    display: flex !important;
    justify-content: center;
    align-items: center;

    li {
      height: auto;
      margin: 0 9px;
      color: ${colors.BORDER};
      ${navTextStyle}

      &.slick-active {
        color: ${colors.PRIMARY};
      }
    }
  }

  .slick-arrow {
    top: auto;
    bottom: ${NAV_HEIGHT / 2}px;
    width: auto;
    height: auto;
    transform: translate(0, 50%);
    color: ${colors.PRIMARY} !important;
    ${navTextStyle}

    &.slick-prev {
      left: calc(50% + 110px);

      &:before {
        display: none;
      }
    }

    &.slick-next {
      right: 40px;
      width: 90px;
      height: 90px;
      overflow: hidden;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid ${colors.PRIMARY};
        border-left-color: ${colors.BORDER};
        border-radius: 100%;
        transform: rotate(45deg);
        opacity: 1;
      }

      span {
        position: relative;
        display: inline-block;

        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: -4px;
          border-bottom: 1px solid ${colors.PRIMARY};
        }

        &:after {
          content: '';
          position: absolute;
          bottom: -4px;
          right: -4px;
          border: 2px solid transparent;
          border-top-color: ${colors.PRIMARY};
          border-left-color: ${colors.PRIMARY};
        }
      }
    }
  }

  ${media.tablet`
    .slick-slider {
      padding-bottom: 63px;
    }

    .slick-dots {
      left: 0;
      width: 100%;
      height: 63px;
      border: none;
    }

    .slick-arrow {
      display: none !important;
    }
  `}
`

export const Img = styled.div`
  height: 100%;
  width: 100%;
  background: url('${props => props.src}') center;
  background-size: cover;

  ${props => props.isCGI && `
    &:after {
      content: 'CGI Indicative Only';
      color: ${props.hasDarkCGILabel ? colors.BLACK : colors.WHITE};
      font-size: 10px;
      position: absolute;
      bottom: 15px;
      right: 15px;
      line-height: 1;
    }
  `}
`
